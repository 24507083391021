import React, { useState } from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { URL_API } from 'gatsby-env-variables';
import { useAuth, useNavigationPopups } from '../contexts/MainAppContext';

const UserLoginFormPopup = () => {
  const { hideAllPopups, showSignupPopup } = useNavigationPopups();
  const { loginUser } = useAuth();

  const [formStatus, setFormStatus] = useState('standby');
  const [formError, setFormError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();

    if (formStatus === 'loading') return;
    setFormStatus('loading');

    try {
      const data = await loginUser(formData.email, formData.password);
      console.log('formSubmit', data);
      navigate('/dashboard');
    } catch (error) {
      setFormStatus('error');
      console.log('formSubmit error', error);

      if (error?.msgs && error?.msgs?.email) {
        setFormError(error?.msgs?.email[0]);
      } else if (error?.msgs && error?.msgs?.password) {
        setFormError(error?.msgs && error?.msgs?.password[0]);
      } else if (error?.msgs && error?.msgs?.length > 0) {
        setFormError(error?.msgs[0]);
      } else {
        setFormError('An communication error has ocurred, please try again.');
      }
    }
  };

  return (
    <>
      <section className="bg-center w-100 bg-white section-popup">
        <div className="flex flex-row xl:mb-10 mb-0 text-black w-100 bg-transparent justify-center" id="login-form">
          <div className="flex flex-row py-10 xl:px-10 px-5 xl:py-10 bg-white rounded-l-lg modal-login justify-center">
            <div className="flex flex-col w-100 justify-center">
              <div className="flex justify-end">
                <button type="button" className="btn-close" onClick={hideAllPopups}>
                  <StaticImage
                    className="w-full xl:max-w-[32px] max-w-[32px] mx-auto"
                    src={'../images/button-close-outline.svg'}
                    formats={['auto', 'webp', 'avif']}
                    width={100}
                    alt="Girar Logo"
                    placeholder="blurred"
                  />
                </button>
              </div>
              <div className="modal-login-body mx-auto my-10 xl:my-10 py-10">
                <h1 className="xl:mt-10 mt-5 xl:text-5xl text-3xl font-bold pb-5 font-rokkit">
                  Login to your <br></br>account
                </h1>
                <form className="flex-col">
                  {formStatus === 'error' ? (
                    <div className="xl:col-span-4 col-span-10">
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-xs" role="alert">
                        {formError}
                      </div>
                    </div>
                  ) : null}

                  <div className="xl:col-span-4 col-span-12 pt-5 mb-5">
                    <p>Email</p>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="xl:col-span-4 col-span-12">
                    <p>Password</p>
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      className="border-b-2 w-full h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="xl:col-span-4 col-span-12">
                    <button
                      type="submit"
                      onClick={(e) => formSubmit(e)}
                      id="login-btn"
                      className="button-dark font-opensans rounded-md  text-xl  h-14 w-full mt-5 transition-all duration-500"
                      disabled={formStatus === 'loading'}>
                      {formStatus === 'loading' ? 'Logging in' : 'Log in'}
                    </button>
                  </div>
                </form>
                <p className="flex-col pt-10 left text-light text-xs">
                  Don't have an Account?{' '}
                  <button onClick={showSignupPopup} className="underline text-black">
                    Sign Up
                  </button>
                </p>
                {/* forgot password link */}
                <p className="pt-4 text-left text-light xl:mb-10  text-xs">
                  Forgot Password?{' '}
                  <Link to="/forgot-password" className="underline text-black">
                    Reset Password
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserLoginFormPopup;
