import React from 'react';
import { useAuth } from '@app/contexts/MainAppContext';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const NavigationUserDropdown = () => {
  const { isUserLoggedIn, userData, logoutUser, isUserPlanFree } = useAuth();

  const menuOptions = [
    { name: 'Dashboard', link: '/dashboard' },
    { name: 'Profile', link: '/account' },
    { name: 'Manage Subscription', link: '/subscription' },
  ];

  const handleLogout = (e) => {
    logoutUser();
    navigate('/');
  };

  return !isUserLoggedIn ? (
    <></>
  ) : (
    <div className="flex-none text-white dropdown-menu profile-component-menu ml-6">
      <button className="dropdown-button font-sans text-lg text-white cursor-pointer transition-all duration-200 font-bold h-[56px]">
        <div className="flex flex-row gap-x-3 items-center">
          <div className="user-selfy mr-0 rounded-full overflow-hidden">
            {userData?.avatar ? (
              <img
                src={userData?.avatar}
                formats={['auto', 'webp', 'avif']}
                width={60}
                alt="avatar image"
                className="mx-auto"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src={'../../images/account-avatar-img.png'}
                formats={['auto', 'webp', 'avif']}
                width={60}
                alt="avatar image"
                className="mx-auto"
                placeholder="blurred"
              />
            )}
          </div>

          <div className="text-left">
            <p className="text-xs font-opensans font-bold text-left truncate max-w-[180px]">{userData.display_name}</p>
            {!isUserPlanFree ? (
              <p className="text-xxs font-opensans text-left mt-0 inline-block">
                Premium &#183; <i className="crown-icon icon inline-block"></i>
              </p>
            ) : (
              <></>
            )}
          </div>
          <i className="dropdown-chevron icon "></i>
        </div>
      </button>
      <div className="dropdown-menu-content dropdown-menu-absolute rounded-lg w-auto ">
        <ul>
          {menuOptions.map((option, index) => (
            <li
              key={index}
              className="whitespace-nowrap py-1 px-2 bg-menu-item text-xs text-right cursor-pointer font-sans text-white mb-0 transition-all duration-200 "
              onClick={() => navigate(option.link)}>
              {option.name}
            </li>
          ))}
          <li className="whitespace-nowrap py-1 px-2 bg-menu-item text-right">
            <a className="text-right font-sans text-xs text-white mb-5 cursor-pointer transition-all duration-200 " onClick={handleLogout}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavigationUserDropdown;
