import React, { useState } from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';
import { useAuth, useNavigationPopups } from '@contexts/MainAppContext';

const UserSignupFormPopup = ({ setOpen }) => {
  const { hideAllPopups, showLoginPopup } = useNavigationPopups();
  const { loginUser, registerUser } = useAuth();

  const [formStatus, setFormStatus] = useState('standby');
  const [formError, setFormError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    display_name: '',
    name: '',
    password: '',
    password_confirmation: '',
    newsletter: false,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = (e) => {
    setFormData({
      ...formData,
      newsletter: !formData.newsletter,
    });
  };

  async function formSubmit(e) {
    e.preventDefault();
    if (formStatus === 'loading') return;

    setFormStatus('loading');
    console.log('formData: ', formData);
    try {
      await registerUser(formData.name, formData.display_name, formData.email, formData.password, formData.password_confirmation);
      await loginUser(formData.email, formData.password);
      navigate(`/pricing-guide`);
    } catch (error) {
      console.log('error', error);
      setFormStatus('error');
      if (error?.msgs?.display_name) {
        setFormError(error?.msgs?.display_name[0]);
      } else if (error?.msgs?.name) {
        setFormError(error?.msgs?.name[0]);
      } else if (error?.msgs?.email) {
        setFormError(error?.msgs?.email[0]);
      } else if (error?.msgs?.password) {
        setFormError(error?.msgs?.password[0]);
      } else if (error?.msgs?.length > 0) {
        setFormError(error?.msgs[0]);
      } else {
        setFormError('An communication error has ocurred, please try again.');
      }
    }
  }

  return (
    <>
      <section className="bg-center w-100 bg-white section-popup">
        <div className="flex flex-row xl:mb-10 mb-0 text-black w-100 bg-transparent lg:justify-end justify-center">
          <div className="flex flex-row py-10 xl:px-10 px-5 xl:py-10 bg-white rounded-l-lg modal-login justify-center">
            <div className="flex flex-col w-100 justify-center">
              <div className="flex justify-end">
                <button type="button" className="btn-close" onClick={hideAllPopups}>
                  <StaticImage
                    className="w-full xl:max-w-[32px] max-w-[32px] mx-auto"
                    src={'../images/button-close-outline.svg'}
                    formats={['auto', 'webp', 'avif']}
                    width={100}
                    alt="Girar Logo"
                    placeholder="blurred"
                  />
                </button>
              </div>
              <div className="modal-login-body mx-auto my-10 xl:my-10 py-10">
                <h1 className="xl:mt-10 mt-5 xl:text-5xl text-3xl font-bold pb-5 font-rokkit">Sign Up</h1>
                <form id="free-account-form" method="post" className="flex-col">
                  {formStatus === 'error' ? (
                    <div className="xl:col-span-4 col-span-10">
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative  text-xs" role="alert">
                        {formError}
                      </div>
                    </div>
                  ) : null}
                  <div className="xl:grid grid-cols-12 gap-8">
                    <div className="xl:col-span-6 col-span-12 mb-3 pt-5">
                      <label htmlFor="d-name">Display Name</label>
                      <input
                        id="d-name"
                        type="text"
                        name="display_name"
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        placeholder="Enter display name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="xl:col-span-6 col-span-12 mb-3 pt-5">
                      <label htmlFor="full-name">Full Name</label>
                      <input
                        id="full-name"
                        type="text"
                        name="name"
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        placeholder="Enter full name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="xl:col-span-4 col-span-12 mb-3">
                    <p className="">E-mail</p>
                    <input
                      className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                      id="email-add"
                      type="email"
                      name="email"
                      required
                      placeholder="Enter e-mail"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="xl:grid grid-cols-12 gap-8">
                    <div className="xl:col-span-6 col-span-12 mb-3">
                      <p className="">Password</p>
                      <input
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        id="pass"
                        type="password"
                        name="password"
                        required
                        placeholder="Enter password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="xl:col-span-6 col-span-12 mb-3">
                      <p className="">Repeat Password</p>
                      <input
                        className="w-full border-b-2 h-12 px-0 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        id="repeat-pass"
                        type="password"
                        name="password_confirmation"
                        required
                        placeholder="Enter password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="xl:col-span-4 col-span-12 mb-3 flex flex-align-center">
                    <input
                      type="checkbox"
                      name="newsletter"
                      id="newsletter"
                      className="mr-2 h-6 w-6"
                      onChange={handleCheckbox}
                      checked={formData.newsletter}
                    />
                    <label htmlFor="newsletter" className="">
                      I want to receive the newsletter
                    </label>
                  </div>

                  <div className="flex justify-center ">
                    <button
                      className="button-dark font-opensans rounded-md  text-xl  h-14 w-full mt-5 transition-all duration-500"
                      type="submit"
                      onClick={formSubmit}
                      disabled={formStatus === 'loading'}>
                      {formStatus === 'loading' ? 'Loading' : 'Get Started'}
                    </button>
                  </div>

                  <input type="reset" value="clear" className="hidden" />
                </form>
                <p className="flex-col pt-10 left text-light xl:mb-10  text-xs">
                  Have an Account?{' '}
                  <button onClick={showLoginPopup} className="underline text-black">
                    Login
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserSignupFormPopup;
